@import 'variables';
@import 'partials';

.browserupgrade {
  margin: 0.2em 0;
  background: $gray-4;
  color: $black;
  padding: 0.2em 0;
}

::selection {
  background: $yellow-6;
  color: $white;
}
::-moz-selection { background: $yellow-6; }

#navigation {
  background-color: $primary-color !important;

  &.navbar-expand-lg .navbar-collapse {
    justify-content: flex-end;
  }

  a {
    color: $white;
    font-family: $primary-font;
    font-size: 1rem;
    font-weight: 300;
  }

  li {
    margin: 0 10px;
  }

  .login-btn {
    padding: 8px 30px;
    font-weight: 400;
    margin: 8px 0;

    &:hover {
      color: $primary-color;
    }
  }

  .navbar-toggler {
    color: $white;
    border-color: $white;
  }

  .custom-toggler.navbar-toggler {
    border-color: rgb(255, 255,255);
  }
  .custom-toggler .navbar-toggler-icon {
    background-image: url("data:image/svg+xml;charset=utf8,%3Csvg viewBox='0 0 32 32' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath stroke='rgb(255, 255,255)' stroke-width='2' stroke-linecap='round' stroke-miterlimit='10' d='M4 8h24M4 16h24M4 24h24'/%3E%3C/svg%3E");
  }
}

.jumbotron {
  color: $white;
  font-family: $secundary-font;
  border-radius: 0;
  height: 600px;
  background: url('../images/jumbotron-bg.png') center bottom no-repeat $primary-color;

  h1 {
    font-size: 24px;
    font-weight: bold;
    margin: -2rem auto 2.5rem auto;
  }

  p {
    font-family: $primary-font;
    margin-bottom: .5rem;
    font-size: 20px;
  }

  .input-group {
    border-radius: 4px;
    background-color: transparent;
    margin: 50px auto 0 auto;

    input {
      height: 56px;
      border-radius: 4px !important;
      border: none;
    }

    button {
      height: 56px;
      font-size: 1rem;
      letter-spacing: .12rem;
      margin-top: .7rem;
    }
  }
}

#funcionalidades {
  padding: 90px 0;

  .title {
    max-width: 800px;
    margin: 0 auto;
    text-align: center;

    h1 {
      font-family: $secundary-font;
      font-size: 2.5rem;
      color: $primary-color;
      margin-bottom: 30px;
    }

    p {
      font-family: $primary-font;
      font-size: 1.2rem;
    }
  }

  img.filtros {
    max-width: 768px;
    width: 100%;
    margin: 40px auto;
  }

  .lista {
    align-items: flex-start;

    > div {
      padding: 0 40px;
    }

    img {
      width: 102px;
      height: 102px;
      margin: 0 auto 40px auto;
    }

    h2 {
      font-family: $secundary-font;
      font-size: 24px;
      color: $primary-color;
      margin-bottom: 15px;
    }

    p {
      color: #535d66;
    }
  }
}

#o-que-e {
  background: url('../images/o-que-e-bg.png') center bottom no-repeat $white;

  .title {
    max-width: 800px;
    margin: 0 auto 70px auto;
    text-align: center;

    h1 {
      font-family: $secundary-font;
      font-size: 2.5rem;
      color: $primary-color;
      margin-bottom: 30px;
    }

    p {
      font-family: $primary-font;
      font-size: 20px;
      line-height: 23px;
    }
  }

  &:before {
    content: "";
    display: block;
    width: 100%;
    height: 40px;
    margin-bottom: 90px;
    background: rgb(255,255,255);
    background: linear-gradient(0deg, rgba(255,255,255,1) 0%, rgba(234,234,234,1) 100%);
  }

  .lista {
    align-items: flex-start;

    > div {
      padding: 0 40px;
      margin-bottom: 50px;
    }

    img {
      width: 80px;
      height: 80px;
      margin: 0 auto 30px auto;
    }

    h3 {
      font: $primary-font;
      font-size: 18px;
      font-style: normal;
      font-weight: bold;
      font-size: 18px;
      line-height: 23px;
    }
  }

  .integracao {
    margin: 80px 0 50px 0;

    a {
      color: $primary-color;
      text-decoration: none;
      background-color: transparent;
      -webkit-text-decoration-skip: objects;
    }
    img {
      max-width: 538px;
      width: 100%;
      margin-bottom: 50px;
    }

    p {
      font: 400 1.2rem/1.7rem $primary-font;
    }
  }
}

#como-funciona {
  background: $primary-color;
  padding: 100px 0 50px;

  h1 {
    text-align: center;
    font-family: $secundary-font;
    font-size: 2.5rem;
    color: $white;
    margin-bottom: 100px;
  }

  ul {
    counter-reset: my-counter;

    li {
      color: $white;
      list-style: none;
      font-size: 1.25rem;
      font-weight: 300;
      margin-bottom: 50px;

      &:before {
        display: block;
        background: url('../images/bullet-bg.png');
        width: 74px;
        height:71px;
        text-align: center;
        padding-top: 20px;
        margin-bottom: 15px;
        content: counter(my-counter) ". ";
        counter-increment: my-counter;
        color: $primary-color !important;
        font-size: 1.5rem !important;
        font-weight: bold !important;
      }
    }
  }
}

#como-adquirir {
  background: #E9F2FB;
  padding: 100px 0;

  .title {
    max-width: 800px;
    margin: 0 auto 50px auto;
    text-align: center;

    h1 {
      font-family: $secundary-font;
      font-size: 2.5rem;
      color: $primary-color;
      margin-bottom: 30px;
    }

    p {
      font-family: $primary-font;
      font-size: 1.2rem;
    }
  }

  form {
    input, textarea {
      font-weight: 300;
      font-size: .9rem;
      padding: 15px;
    }

    button {
      padding: 15px 65px;
      margin-top: 20px;
      font-size: 1rem;
      letter-spacing: .12rem;
    }
  }
}

footer {
  padding: 60px 0 120px;

  ul {
    padding: 0;
    margin-bottom: 20px;

    li {
      list-style: none;
      color: #535d66;

      ul {
        padding: 0;
      }

      h3 {
        font-family: $primary-font;
        font-size: 1rem;
        color: $primary-color;
      }

      a {
        font-weight: 300;
        color: #535d66;
        line-height: 1.75rem;

        &.strong {
          font-weight: 500;
        }
      }
    }
  }

  .social {
    margin-top: 60px;
    border-top: 1px solid #535d66;

    ul {
      margin-top: 30px;

      li {
        float: left;
        margin: 20px;

        a {
          text-decoration: none;
          font-size: 1.4rem
        }
      }
    }
  }

  p {
    color: #535d66;
  }

}

@import "midia-queries";
