@import 'variables';
@import 'partials';

/*
####################################################
M E D I A  Q U E R I E S
####################################################
*/

/* Small devices (landscape phones, 576px and up) */
// @media (min-width: 576px) {
// }

/* Medium devices (tablets, 768px and up) The navbar toggle appears at this breakpoint */
@media (min-width: 768px) {
  .jumbotron h1 {
    font-size: 2.7rem;
  }
}

/* Large devices (desktops, 992px and up) */
// @media (min-width: 992px) {
// }

/* Extra large devices (large desktops, 1200px and up) */
// @media (min-width: 1200px) {
// }
